<template>
  <section class='price'>
    <div class="price_wrap">
      <div class="top">
        <div class="title">助力律师智慧办公，为律所信息化建设提供解决方案！<span class="tips">请选择合适的方案!</span></div>
      </div>
      <div class="type">
        <div class="type_item" v-for="(item, index) in state.list" :key="index">
          <div class="header">
            <div class="theme_wrap">
              <div class="theme">{{item.productName}}</div>
              <div class="subtheme">{{item.description}}</div>
            </div>
            <el-popover placement="top-start" :width="200" trigger="click" :content="item.remark">
              <template #reference>
                <div class="question">?</div>
              </template>
            </el-popover>
          </div>
          <div class="middle">
            <div class="txt" v-if="item.price == 0">终身免费</div>
            <div class="cash_wrap" v-else>
              <div class="cash_icon">￥</div>
              <div class="cash_num">{{item.price}}</div>
              <div class="cash_txt">{{'元/' + item.priceCycleName}}</div>
            </div>
            <el-button class="btn" @click="handlePurchase(item)">{{item.price == 0 ? '注册' : '购买'}}</el-button>
          </div>
          <div class="bottom" v-if="item.stdFunctionList.length>0 || item.nonStdFunctionList.length>0">
            <div class="list_wrap">
              <!-- 标配 -->
              <div class="list_item" v-for="(item2, index) in item.stdFunctionList" :key="index">
                <div class="major_wrap" @click="handleClickMajor(item2)">
                  <div class="tag">标配</div>
                  <div class="icon">{{item2.expand ? '-' : '+'}}</div>
                  <div class="con">{{ item2.functionName }}</div>
                </div>
                <!-- 标配子级 -->
                <div v-if="item2.expand">
                  <div class="sub_wrap" v-for="(itemChildren, index) in item2.children" :key="index">
                    <div class="tag">标配</div>
                    <div class="icon">-</div>
                    <div class="con">{{itemChildren.functionName}}</div>
                  </div>
                </div>
              </div>
              <!-- 选配 -->
              <div class="list_item" v-for="(selectItem, index) in item.nonStdFunctionList" :key="index">
                <div class="major_wrap" @click="handleClickMajor(selectItem)">
                  <div class="tag standard">选配</div>
                  <div class="icon">{{selectItem.expand ? '-' : '+'}}</div>
                  <div class="con">{{ selectItem.functionName }}</div>
                </div>
                <!-- 选配子级 -->
                <div v-if="selectItem.expand">
                  <div class="sub_wrap" v-for="(itemChildren, index) in selectItem.children" :key="index">
                    <div class="tag standard">选配</div>
                    <div class="icon">-</div>
                    <div class="con">{{itemChildren.functionName}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <MyDialog :isShow="showDialog" :title="dialogTitle" :closeDialog="closeDialog">
      <div class="pay_dialog">
        <div class="pay_item">
          <div class="title">购买方案:</div>
          <div class="con">{{state.payForm.price + '元/' + state.payForm.priceCycleName}}</div>
        </div>
        <div class="pay_item">
          <div class="title">购买时长:</div>
          <div class="con">
            <el-input-number v-model="state.payForm.quantity" @change="handleChangeNum" :min="1" size="large">
            </el-input-number> <span class="price_cycle">{{state.payForm.priceCycleName}}</span>
          </div>
        </div>
        <div class="pay_item">
          <div class="title">购买后:</div>
          <div class="con">有效期至:{{state.payForm.endDate}}</div>
        </div>
        <div class="pay_item">
          <div class="title">支付方式:</div>
          <div class="con">
            <el-radio v-model="state.payForm.radio" label="1" border>微信支付</el-radio>
          </div>
        </div>
        <div class="btn_wrap">
          <div class="total_price">
            总价: ￥<span>{{state.payForm.totalPrice}}</span>
          </div>
          <el-button type="primary" @click="handlePay">支付</el-button>
        </div>
      </div>
    </MyDialog>
    <!-- 二维码弹框 -->
    <MyDialog :isShow="showCodeDialog" title="支付" :closeDialog="closeDialog">
      <div class="code_dialog">
        <!-- <vue-qr :logo-src="logoSrc" :size="300" :margin="0" :auto-color="true" :dot-scale="1" :text="state.appSrc" /> -->
        <div class="qrcode" id="qrcode"></div>
      </div>
    </MyDialog>
  </section>
</template>

<script>
  import MyDialog from '@/components/myDialog'
  import QRCode from 'qrcodejs2'
  // import VueQr from 'vue-qr'
  import {
    ref,
    reactive,
    onMounted
  } from 'vue'
  import {
    useRouter
  } from 'vue-router'
  import {
    getProductAll,
    getDate,
    getPayOrder,
    getPrePayOrder,
    wxPayCB
  } from '@/api/price'
  export default {
    name: 'Price',
    components: {
      MyDialog,
      // VueQr
    },
    data() {
      return {
        logoSrc: '',
        appSrc: '',
        productId: 1,
        showDialog: false,
        showCodeDialog: false,
        dialogTitle: '',
        timer: null,
        state: {
          list: [],
          date: new Date(),
          appSrc: null,
          payForm: {
            radio: '1',
            quantity: 1,
            totalPrice: 0,
            endDate: '',
            priceCycle: null,
            priceCycleName: null
          }
        }
      }
    },
    created() {
      this.getList()
    },
    methods: {
      async handlePay() {
        let params = {
          productId: this.productId,
          quantity: this.state.payForm.quantity,
          relType: 1
        }
        let res = await getPayOrder(params)
        if (res.code == 200) {
          let params2 = {
            openId: '',
            payOrderId: res.data.payOrderId,
            wxPayType: 'NATIVE'
          }
          let res2 = await getPrePayOrder(params2)
          console.log(res2);
          if (res2.code == 200) {
            this.showCodeDialog = true
            this.state.appSrc = res2.data.codeUrl
            // console.log(object);
            // console.log(state.appSrc);
            // new QRCode('',{
            //   text: res2.data.codeUrl,
            //   width: 300,
            //   height: 300,
            //   correctLevel: QRCode.CorrectLevel.H
            // })
            this.$nextTick(()=>{
              new QRCode(document.querySelector('#qrcode'),res2.data.codeUrl)
            })
            this.timer = setInterval(async () => {
              let res3 = await wxPayCB({payOrderId:res.data.payOrderId})
              // console.log(res3);
              if(res3.data.status == 2){
                this.showCodeDialog = false
                this.showDialog = false
                clearInterval(this.timer)
              }
            }, 2000);
          }
        }
      },
      handlePurchase(item) {
        // console.log(productId);
        this.productId = item.productId
        if (item.productId == 1) {
          this.$router.push('/register')
        } else {
          this.showDialog = true
          this.dialogTitle = item.productName
          this.state.payForm.price = item.price
          this.state.payForm.totalPrice = item.price
          this.state.payForm.priceCycle = item.priceCycle
          this.state.payForm.priceCycleName = item.priceCycleName
          this.handleChangeNum(1)
        }
      },
      async handleChangeNum(val) {
        let params = {
          numbers: val,
          productId: this.productId
        }
        let res = await getDate(params)
        this.state.payForm.endDate = res.data
        this.state.payForm.totalPrice = val * this.state.payForm.price
      },
      async getList() {
        let res = await getProductAll()
        if(res&&res.code == 200){
          res.data.map((item) => {
            item.stdFunctionList.map((item2) => {
              item2.expand = false
            })
            item.nonStdFunctionList.map((item3) => {
              item3.expand = false
            })
          })
          this.state.list = res.data
        }
      },
      closeDialog() {
        this.showDialog = false
        this.showCodeDialog = false
        clearInterval(this.timer);
        // 重置
        this.state.payForm.radio = '1';
        this.state.payForm.quantity = 1;
        this.state.payForm.totalPrice = 0;
        this.state.payForm.endDate = '';
        this.state.payForm.priceCycle = null;
        this.state.payForm.priceCycleName = null;
      }
    },
    setup() {

      const router = useRouter()
      const handleClickMajor = (item) => {
        item.expand = !item.expand
      }
      
      return {

        handleClickMajor,

      }
    }
  }
</script>
<style lang='scss' scoped>
  //@import url(); 引入公共css类
  @import '@/assets/style/comman.scss';

  .price {
    width: 100%;
    // height: calc(100vh - 160px);
    display: flex;
    justify-content: center;
    align-items: flex-start;

    .price_wrap {
      // height: 560px;
      // height: 90%;
      width: 100%;
      background: #fff;
      border-radius: 20px;
      padding: 40px;
      box-sizing: border-box;

      .top {
        .title {
          font-size: 25px;
          color: #333;
          text-align: center;
        }

        .tips {
          font-size: 14px;
          color: #aaa;
          margin-left: 30px;
        }
      }

      .type {
        width: 1100px;
        margin: 20px auto;
        // overflow: hidden;
        // display: flex;
        // justify-content: space-between;

        .type_item {
          float: left;
          width: 310px;
          margin: 0 20px;
          background: #fff4ee;
          border-radius: 10px 10px 0 0;


          .header {
            height: 70px;
            background: #fb9661;
            position: relative;
            border-radius: 10px 10px 0 0;

            .theme_wrap {
              color: #fff;
              text-align: center;
              overflow: hidden;

              .theme {
                font-size: 18px;
                font-weight: 900;
                line-height: 30px;
                margin-top: 10px;
              }

              .subtheme {
                font-size: 12px;
                margin-top: 5px;
              }
            }

            .question {
              position: absolute;
              width: 15px;
              height: 15px;
              border-radius: 50%;
              border: 1px solid #fff;
              color: #fff;
              line-height: 15px;
              font-size: 12px;
              text-align: center;
              top: 17px;
              right: 20px;
              cursor: pointer;
            }
          }

          .middle {
            height: 130px;
            overflow: hidden;

            .txt {
              font-size: 35px;
              color: #333;
              text-align: center;
              margin-top: 20px;
              height: 40px;
              line-height: 40px;
            }

            .btn {
              display: block;
              width: 150px;
              height: 40px;
              border: 1px solid $main-color;
              color: $main-color;
              padding: 0;
              font-size: 14px;
              margin: 10px auto;
            }

            .cash_wrap {
              display: flex;
              align-items: flex-end;
              justify-content: center;
              margin-top: 20px;

              .cash_icon {
                font-size: 12px;
                color: #aaa;
              }

              .cash_num {
                font-size: 40px;
                color: #333;
                font-weight: 900;
              }

              .cash_txt {
                font-size: 20px;
                color: #333;
              }
            }
          }

          .bottom {
            // @media screen and (max-width : 1600px) {
            //   max-height: 230px;
            // }

            // max-height: 400px;
            // overflow: auto;
            border-top: 1px solid $main-color;

            .list_wrap {
              .list_item {

                .major_wrap,
                .sub_wrap {
                  height: 34px;
                  display: flex;
                  align-items: center;
                  border-bottom: 1px solid $main-color;
                  color: #333;
                  padding: 0 10px;
                  cursor: pointer;

                  .tag {
                    flex: 0 0 32px;
                    height: 20px;
                    line-height: 20px;
                    text-align: center;
                    font-size: 12px;
                    color: $main-color;
                    border: 1px solid #7d7575;

                    &.standard {
                      color: #74eab0;
                    }
                  }

                  .icon {
                    margin: 0 10px;
                  }

                  .con {
                    font-size: 14px;
                    @include ellipsis;
                  }
                }

                .sub_wrap {
                  cursor: auto;
                  color: #7d7575;
                  border-bottom: 1px solid #7d7575;

                  .icon {
                    margin-left: 20px;
                  }
                }
              }
            }
          }

          &:nth-of-type(2) {
            .header {
              background: #fb7882;
            }
          }

          &:nth-of-type(3) {
            .header {
              background: #fb6161;
            }
          }
        }
      }
    }
  }

  .pay_dialog {
    padding: 10px;

    .pay_item {
      height: 40px;
      display: flex;
      align-items: center;

      .title {
        width: 120px;
        text-align: left;
      }

      .con {
        .price_cycle {
          margin-left: 10px;
        }
      }
    }

    .btn_wrap {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      color: #333;
      padding: 0 20px;

      span {
        font-size: 30px;
        font-weight: 900;
        margin: 0 10px;
      }
    }
  }

  .code_dialog {
    padding: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 300px;
      height: 300px;
    }
  }
</style>